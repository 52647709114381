import { paths } from 'types/api';
import { baseApi, KeysToCamelcase, UsersTags, UserTags } from '../baseApi';

export type GetUserQueryResponse =
  paths['/api/v1/users/me']['get']['responses']['200']['content']['application/json'];

// 将来の /users/:id を想定しての実装
type GetUserQueryParams = KeysToCamelcase<
  | paths['/api/v1/users/{user_id}']['get']['parameters']['path']
  | { user_id: 'me' }
>;

type GetUserMfaDeviceResponse =
  paths['/api/v1/users/mfa_device']['post']['responses']['200']['content']['application/json'];

type GetUserMfaDeviceParams = KeysToCamelcase<
  paths['/api/v1/users/mfa_device']['post']['requestBody']['content']['application/json']
>;

type InitializeMfaResponse =
  paths['/api/v1/users/initialize_mfa']['post']['responses']['200']['content']['application/json'];
type InitializeMfaParams = KeysToCamelcase<
  paths['/api/v1/users/initialize_mfa']['post']['requestBody']['content']['application/json']
>;

type PostUserVerifyMfaDeviceResponse =
  paths['/api/v1/users/verify_mfa_device']['post']['responses']['200']['content']['application/json'];
type PostUserVerifyMfaDeviceParams = KeysToCamelcase<
  paths['/api/v1/users/verify_mfa_device']['post']['requestBody']['content']['application/json']
>;

type DeleteUserMfaDeviceResponse =
  paths['/api/v1/users/mfa_device']['delete']['responses']['200']['content']['application/json'];
type DeleteUserMfaDeviceParams = KeysToCamelcase<
  paths['/api/v1/users/mfa_device']['delete']['requestBody']['content']['application/json']
>;

type SignOutResponse =
  paths['/api/v1/users/sign_out']['delete']['responses']['200']['content']['application/json'];
type SignOutParams = KeysToCamelcase<
  paths['/api/v1/users/sign_out']['delete']['requestBody']['content']['application/json']
>;

type ChangePasswordResponse =
  paths['/api/v1/users/change_password']['put']['responses']['200']['content']['application/json'];
type ChangePasswordParams = KeysToCamelcase<
  paths['/api/v1/users/change_password']['put']['requestBody']['content']['application/json']
>;
export type GetUsersQueryParams = KeysToCamelcase<
  paths['/api/v1/users']['get']['parameters']['query']
>;
export type GetUsersQueryResponse =
  paths['/api/v1/users']['get']['responses']['200']['content']['application/json'];

type PostUsersParams =
  paths['/api/v1/users']['post']['requestBody']['content']['application/json'];
type PostUsersResponse =
  paths['/api/v1/users']['post']['responses']['200']['content']['application/json'];

type PutUsersParams =
  paths['/api/v1/users/{user_id}']['put']['requestBody']['content']['application/json'] & {
    id: string;
  };
type PutUsersResponse =
  paths['/api/v1/users/{user_id}']['put']['responses']['200']['content']['application/json'];

type DeactivateUserResponse =
  paths['/api/v1/users/{user_id}']['delete']['responses']['200']['content']['application/json'];
type DeactivateUserParams = KeysToCamelcase<
  paths['/api/v1/users/{user_id}']['delete']['parameters']['path']
>;

type ActivateUserResponse =
  paths['/api/v1/users/{user_id}/activate']['put']['responses']['200']['content']['application/json'];
type ActivateUserParams = KeysToCamelcase<
  paths['/api/v1/users/{user_id}/activate']['put']['parameters']['path']
>;

type DeleteMfaDeviceByAdminResponse =
  paths['/api/v1/admin/users/{user_id}/mfa_device']['delete']['responses']['200']['content']['application/json'];

type DeleteMfaDeviceByAdminParams =
  paths['/api/v1/admin/users/{user_id}/mfa_device']['delete']['parameters']['path'];

type GetIfUserMfaRegisteredParams =
  paths['/api/v1/admin/users/{user_id}/mfa_device']['get']['parameters']['path'];
type GetIfUserMfaRegisteredResponse =
  paths['/api/v1/admin/users/{user_id}/mfa_device']['get']['responses']['200']['content']['application/json'];

// see) https://staging.shelf.pfn.dev/docs#/users
export const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<GetUserQueryResponse, GetUserQueryParams>({
      query: (params) => ({
        url: `users/${params.userId}`,
      }),
      providesTags: [UserTags.GetUser, UserTags.UpdateUser],
    }),
    getMfaDevice: builder.query<
      GetUserMfaDeviceResponse,
      GetUserMfaDeviceParams
    >({
      query: (params) => ({
        url: 'users/mfa_device',
        method: 'POST',
        body: { access_token: params.accessToken },
      }),
      providesTags: [UsersTags.VerifyMfaDevice, UsersTags.DeleteMfaDevice],
    }),
    initializeMfa: builder.mutation<InitializeMfaResponse, InitializeMfaParams>(
      {
        query: (params) => ({
          url: 'users/initialize_mfa',
          method: 'POST',
          body: { access_token: params.accessToken },
        }),
      }
    ),
    verifyMfaDevice: builder.mutation<
      PostUserVerifyMfaDeviceResponse,
      PostUserVerifyMfaDeviceParams
    >({
      query: (params) => ({
        url: 'users/verify_mfa_device',
        method: 'POST',
        body: {
          access_token: params.accessToken,
          mfa_token: params.mfaToken,
        },
      }),
      invalidatesTags: [UsersTags.VerifyMfaDevice],
    }),
    deleteMfaDevice: builder.mutation<
      DeleteUserMfaDeviceResponse,
      DeleteUserMfaDeviceParams
    >({
      query: (params) => ({
        url: 'users/mfa_device',
        method: 'DELETE',
        body: { access_token: params.accessToken },
      }),
      invalidatesTags: [UsersTags.DeleteMfaDevice],
    }),
    signOut: builder.mutation<SignOutResponse, SignOutParams>({
      query: (params) => ({
        url: 'users/sign_out',
        method: 'DELETE',
        body: { refresh_token: params.refreshToken },
      }),
    }),
    changePassword: builder.mutation<
      ChangePasswordResponse,
      ChangePasswordParams
    >({
      query: (params) => ({
        url: 'users/change_password',
        method: 'PUT',
        body: {
          access_token: params.accessToken,
          old_password: params.oldPassword,
          new_password: params.newPassword,
        },
      }),
    }),
    getUsers: builder.query<GetUsersQueryResponse, GetUsersQueryParams>({
      query: (params) => ({
        url: `users`,
        method: 'GET',
        params: {
          offset: params?.offset,
          limit: params?.limit,
          q: params?.q,
          fullname: params?.fullname,
          email: params?.email,
          role: params?.role,
          scope: params?.scope,
          deleted_user: params?.deletedUser,
          created_at_start: params?.createdAtStart,
          created_at_end: params?.createdAtEnd,
          updated_at_start: params?.updatedAtStart,
          updated_at_end: params?.updatedAtEnd,
          first_order: params?.firstOrder,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (currentCache.pager.offset === newItems.pager.offset) {
          currentCache.pager = newItems.pager;
          currentCache.users = [...newItems.users];
        } else {
          currentCache.users.push(...newItems.users);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: [
        UserTags.GetUsers,
        UserTags.CreateUser,
        UserTags.UpdateUser,
      ],
    }),
    createUser: builder.mutation<PostUsersResponse, PostUsersParams>({
      query: (params) => ({
        url: `users`,
        method: 'POST',
        body: {
          fullname: params.fullname,
          email: params.email,
          role: params.role,
        },
      }),
      invalidatesTags: [UserTags.GetUsers],
    }),
    updateUser: builder.mutation<PutUsersResponse, PutUsersParams>({
      query: (params) => ({
        url: `/users/${params.id}`,
        method: 'PUT',
        body: {
          fullname: params.fullname,
          email: params.email,
          role: params.role,
        },
      }),
      invalidatesTags: [UserTags.GetUsers, UserTags.GetUser],
    }),
    deactivateUser: builder.mutation<
      DeactivateUserResponse,
      DeactivateUserParams
    >({
      query: (params) => ({
        url: `/users/${params.userId}`,
        method: 'DELETE',
      }),
    }),
    activateUser: builder.mutation<ActivateUserResponse, ActivateUserParams>({
      query: (params) => ({
        url: `/users/${params.userId}/activate`,
        method: 'PUT',
      }),
    }),
    deleteMfaDeviceByAdmin: builder.mutation<
      DeleteMfaDeviceByAdminResponse,
      DeleteMfaDeviceByAdminParams
    >({
      query: (params) => ({
        url: `admin/users/${params.user_id}/mfa_device`,
        method: 'DELETE',
      }),
      invalidatesTags: [UserTags.GetIfUserMfaRegistered],
    }),
    getIfUserMfaRegistered: builder.query<
      GetIfUserMfaRegisteredResponse,
      GetIfUserMfaRegisteredParams
    >({
      query: (params) => ({
        url: `admin/users/${params.user_id}/mfa_device`,
        method: 'GET',
      }),
      providesTags: [
        UserTags.DeleteMfaDeviceByAdmin,
        UserTags.GetIfUserMfaRegistered,
      ],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetMfaDeviceQuery,
  useInitializeMfaMutation,
  useVerifyMfaDeviceMutation,
  useDeleteMfaDeviceMutation,
  useSignOutMutation,
  useChangePasswordMutation,
  useLazyGetUsersQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeactivateUserMutation,
  useActivateUserMutation,
  useDeleteMfaDeviceByAdminMutation,
  useGetIfUserMfaRegisteredQuery,
} = usersApi;
