import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RealogramSelectedItem } from 'types/realogram';

type RealogramCandidateState = {
  selectedRealogramItem?: RealogramSelectedItem;
  ratio: number;
  storeBay: string;
};

const initialState: RealogramCandidateState = {
  selectedRealogramItem: undefined,
  ratio: 0,
  storeBay: '',
};

const realogramCandidateSlice = createSlice({
  name: 'realogramCandidate',
  initialState,
  reducers: {
    reset: () => initialState,
    updateSelectedRealogramItem: (
      state: RealogramCandidateState,
      action: PayloadAction<RealogramSelectedItem | undefined>
    ) => {
      state.selectedRealogramItem = action.payload;
    },
    updateRatio: (
      state: RealogramCandidateState,
      action: PayloadAction<number>
    ) => {
      state.ratio = action.payload;
    },
    setRealogramCandidateStoreBay: (
      state: RealogramCandidateState,
      action: PayloadAction<string>
    ) => {
      state.storeBay = action.payload;
    },
  },
});

export const {
  updateSelectedRealogramItem,
  reset,
  updateRatio,
  setRealogramCandidateStoreBay,
} = realogramCandidateSlice.actions;

export const RealogramCandidateReducer = realogramCandidateSlice.reducer;
