import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  EditorProductBase,
  EliminationCandidate,
  TimeSeries,
} from '@components/pages/sakura/types';
import { RootState } from '@store/index';
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- External library import needed
import _ from 'lodash';
import { sakuraApi } from '@reducers/shelfAppsApi/injections/sakuraApi';

export type ItemCd = string;

export const ProductTag = {
  Kihon: 1, // 基本商品
  New: 2, // 新商品
  Informed: 3, // 情報アリ
  RecommendedToCancel: 4, // 推奨取消
  EliminationCandidate: 5, // 死筋候補
} as const;
export type ProductTag = (typeof ProductTag)[keyof typeof ProductTag];

export type EditorProduct = EditorProductBase & {
  tags: ProductTag[];
  tagsSupplementalText: Partial<Record<ProductTag, string>>;
  timeSeries: TimeSeries;
};

// モデゴン掲載商品に付与するメダルなどのアノテーション
export type ModegonProductAnnotation = {
  top1: boolean;
  top2: boolean;
  top3: boolean;
  top4: boolean;
  top5: boolean;
};

export const ModegonProductAnnotationDefault: Readonly<ModegonProductAnnotation> =
  {
    top1: false,
    top2: false,
    top3: false,
    top4: false,
    top5: false,
  };

export type StandardModegonProduct = {
  itemCd: ItemCd; // 商品コード
  row: number; // 棚段
  col: number; // 列
  faces: number; // フェイス数
  comment: string; // 自由記述のコメント
};

export type BevModegonProduct = {
  itemCd: ItemCd; // 商品コード
  row: number; // 棚段 (1: OPケース側, 2: ゴンドラ側)
  basket: number; // カゴ
  index: number; // カゴ内での位置
  comment: string; // 自由記述のコメント
};

export type ModegonProduct = StandardModegonProduct | BevModegonProduct;

export function isStandardModegonProduct(
  p: ModegonProduct | undefined
): p is StandardModegonProduct {
  return (p as StandardModegonProduct)?.col !== undefined;
}

export function isBevModegonProduct(
  p: ModegonProduct | undefined
): p is BevModegonProduct {
  return (
    (p as BevModegonProduct)?.basket !== undefined &&
    (p as BevModegonProduct)?.index !== undefined
  );
}

export function modegonProductLocationText(
  modegonProduct: ModegonProduct
): string {
  if (isStandardModegonProduct(modegonProduct))
    return `${modegonProduct.row}段/${modegonProduct.col}列`;
  else if (isBevModegonProduct(modegonProduct))
    return `${modegonProduct.row == 1 ? 'OPケース側' : 'ゴンドラ側'}/カゴ${
      modegonProduct.basket
    }`;

  return '';
}

export type EliminateProduct = EliminationCandidate & {
  selectedAsEliminate: boolean; // 推奨取消・死筋リストに載せると判断されたか否か. trueで載せると判断する
};

export type ModegonContext = {
  date: string | undefined; // base date of the data. ex: 20241011
  doCode: string | undefined; // key of DoList
  gondola: string | undefined; // key of GondolaList
};

export function tagsFromProduct(
  product: EditorProductBase,
  eliminationCandidate: EliminationCandidate | undefined
): {
  tags: ProductTag[];
  tagsSupplementalText: Partial<Record<ProductTag, string>>;
} {
  const tags = [
    product.kihon ? ProductTag.Kihon : undefined,
    product.newItem ? ProductTag.New : undefined,
    product.annotation ? ProductTag.Informed : undefined,
    eliminationCandidate?.type == 1
      ? ProductTag.RecommendedToCancel
      : undefined,
    eliminationCandidate?.type == 2
      ? ProductTag.EliminationCandidate
      : undefined,
  ].filter((t) => t != undefined) as ProductTag[];

  const texts: Partial<Record<ProductTag, string>> = {};
  if (tags.includes(ProductTag.Kihon)) texts[ProductTag.Kihon] = '基本商品';
  if (tags.includes(ProductTag.New))
    texts[ProductTag.New] = `発売日: ${product.startDate}`;
  if (tags.includes(ProductTag.Informed))
    texts[ProductTag.Informed] = product.annotation || '';
  if (tags.includes(ProductTag.RecommendedToCancel))
    texts[ProductTag.RecommendedToCancel] = eliminationCandidate?.reason || '';
  if (tags.includes(ProductTag.EliminationCandidate))
    texts[ProductTag.EliminationCandidate] = eliminationCandidate?.reason || '';
  return {
    tags: tags,
    tagsSupplementalText: texts,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Using any temporarily due to complex Sakura data structure
export function getProductsMap(SelectorFunc: any): Map<ItemCd, EditorProduct> {
  const products = (SelectorFunc(selectProducts) as EditorProduct[]) || [];
  return new Map<ItemCd, EditorProduct>(
    products.map((p) => {
      return [p.itemCd, p];
    })
  );
}

type NumberTypeKeys<T> = {
  [K in keyof T]: T[K] extends number ? K : never;
}[keyof T];

function sortKeyToSortScore(v: number, max: number): number {
  return v < 0 ? max : v;
}

function sortModegonProductsGeneric<T>(
  products: T[],
  groupKeyFn: (p: T) => string,
  sortKey: NumberTypeKeys<T> // number 型をもつキーに限定
): T[] {
  type GroupObj = { [key: string]: T[] };
  const groups = products.reduce((group, p) => {
    const key = groupKeyFn(p);
    if (!group[key]) group[key] = [];
    group[key].push(p);
    return group;
  }, {} as GroupObj);

  let ret: T[] = [];
  for (const key in groups) {
    const g = groups[key];
    const gMax = g.length + 1;
    const sorted = groups[key].sort(
      (a, b) =>
        sortKeyToSortScore(a[sortKey] as number, gMax) -
        sortKeyToSortScore(b[sortKey] as number, gMax)
    );

    ret = ret.concat(
      sorted.map((p, i) => {
        return { ...p, [sortKey]: i + 1 };
      })
    );
  }

  return ret;
}

function sortModegonProducts(products: ModegonProduct[]): ModegonProduct[] {
  if (products.length == 0) {
    return [];
  } else if (isStandardModegonProduct(products[0])) {
    return sortModegonProductsGeneric(
      products as StandardModegonProduct[],
      (p) => `${p.row}`,
      'col'
    );
  } else if (isBevModegonProduct(products[0])) {
    return sortModegonProductsGeneric(
      products as BevModegonProduct[],
      (p) => `${p.row}-${p.basket}`,
      'index'
    );
  }
  console.error('Invalid data type at ModegonProducts');
  return [];
}

export type SerializeFormat = {
  context: ModegonContext;
  products: EditorProduct[];
  modegonProducts: ModegonProduct[];
  eliminateProducts: EliminateProduct[];
};

export const serializeData = (
  context: ModegonContext,
  products: EditorProduct[],
  modegonProducts: ModegonProduct[],
  eliminateProducts: EliminateProduct[]
): SerializeFormat => {
  const itemCds = [
    ...modegonProducts.map((p) => p.itemCd),
    ...eliminateProducts.map((p) => p.itemCd),
  ];

  return {
    context: context,
    products: products.filter((p) => {
      return itemCds.includes(p.itemCd);
    }),
    modegonProducts: modegonProducts,
    eliminateProducts: eliminateProducts.filter((ep) => ep.selectedAsEliminate), // 選択されているもののみ保存する
  };
};

export type SakuraEditorState = {
  context: ModegonContext;
  products: EditorProduct[];
  modegonProducts: ModegonProduct[];
  eliminationCandidate: EliminateProduct[];
  previousModegonItems: ItemCd[];
};

const sakuraEditorStateSlice = createSlice({
  name: 'sakuraEditorState',
  initialState: {
    context: {
      doCode: undefined,
      gondola: undefined,
      date: undefined,
    },
    products: [],
    modegonProducts: [],
    eliminationCandidate: [],
    previousModegonItems: [],
  } as SakuraEditorState,
  reducers: {
    setContext: (
      state: SakuraEditorState,
      action: PayloadAction<ModegonContext>
    ) => {
      state.context = action.payload;
    },

    setProducts: (
      state: SakuraEditorState,
      action: PayloadAction<{
        products: EditorProduct[];
        eliminationCandidates: EliminateProduct[];
      }>
    ) => {
      const { products, eliminationCandidates } = action.payload;
      state.products = products;
      state.eliminationCandidate = eliminationCandidates;
    },

    addModegonProduct: (
      state: SakuraEditorState,
      action: PayloadAction<ModegonProduct>
    ) => {
      // check types are valid
      if (state.modegonProducts.length > 0) {
        const current = state.modegonProducts[0];
        if (
          isStandardModegonProduct(current) &&
          !isStandardModegonProduct(action.payload)
        )
          throw 'Unexpected types for state and payload';
        if (
          isBevModegonProduct(current) &&
          !isBevModegonProduct(action.payload)
        )
          throw 'Unexpected types for state and payload';
      }

      if (
        state.modegonProducts
          .map((p) => p.itemCd)
          .includes(action.payload.itemCd)
      ) {
        throw 'Already in modegon';
      }

      // 商品を追加し、ソートしている
      state.modegonProducts = sortModegonProducts([
        ...state.modegonProducts,
        ...[action.payload], // ここで商品を追加
      ]);
      state.previousModegonItems = state.previousModegonItems.filter(
        (p) => p != action.payload.itemCd
      );
    },

    // update modegon product
    updateModegonProduct: (
      state: SakuraEditorState,
      action: PayloadAction<ModegonProduct>
    ) => {
      const newState = state.modegonProducts.map((p) => {
        return p.itemCd == action.payload.itemCd ? action.payload : p;
      });

      state.modegonProducts = sortModegonProducts(newState);
    },

    // delete modegon product
    removeModegonProduct: (
      state: SakuraEditorState,
      action: PayloadAction<ItemCd>
    ) => {
      state.modegonProducts = state.modegonProducts.filter(
        (p) => p.itemCd != action.payload
      );
      state.previousModegonItems.push(action.payload);
    },

    clearModegonProduct: (
      state: SakuraEditorState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Payload not used but required for type definition
      action: PayloadAction<void>
    ) => {
      state.modegonProducts = [];
      state.previousModegonItems = [];
    },

    setPreviousModegonProducts: (
      state: SakuraEditorState,
      action: PayloadAction<ItemCd[]>
    ) => {
      state.previousModegonItems = action.payload;
    },
  },

  extraReducers(builder) {
    builder.addMatcher(
      // get product master API のレスポンスをstateに格納する
      sakuraApi.endpoints.getProductMaster.matchFulfilled,
      (state, action) => {
        const response = action.payload;

        // 商品一覧にグラフの時系列情報, タグを付与する
        state.products = response.products.map((p) => {
          return {
            ...p,
            ...{
              timeSeries: response.timeSeries[p.itemCd],
            },
            ...tagsFromProduct(
              p,
              response.eliminationCandidate.find((e) => e.itemCd == p.itemCd)
            ),
          };
        });

        // カット対象商品の一覧(ほぼ使われていないのでdeprecated)
        state.eliminationCandidate = response.eliminationCandidate.map((e) => {
          return { ...e, ...{ selectedAsEliminate: true } };
        });

        // モデゴンにある商品は商品一覧に必ず含まれる
        // ゴンドラが変わって商品一覧が変更になる可能性があるのでないものを削除
        const productItemCds = response.products.map((p) => p.itemCd);
        state.modegonProducts = state.modegonProducts.filter((p) =>
          productItemCds.includes(p.itemCd)
        );
      }
    );
  },
});

export const {
  setContext,
  setProducts,
  addModegonProduct,
  updateModegonProduct,
  removeModegonProduct,
  clearModegonProduct,
  setPreviousModegonProducts,
} = sakuraEditorStateSlice.actions;
export const SakuraEditorStateReducer = sakuraEditorStateSlice.reducer;

const addProductToModegonSlice = createSlice({
  name: 'addProductToModegonItemCd',
  initialState: '' as ItemCd,
  reducers: {
    setAddProductToModegonItemCd(state: ItemCd, action: PayloadAction<ItemCd>) {
      return action.payload;
    },
    clearAddProductToModegonItemCd(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Type definition only used for Sakura state management
      state: undefined | ItemCd,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Payload not used but required for type definition
      action: PayloadAction<void>
    ) {
      return '';
    },
  },
});

export const { setAddProductToModegonItemCd, clearAddProductToModegonItemCd } =
  addProductToModegonSlice.actions;
export const SakuraAddProductToModegonReducer =
  addProductToModegonSlice.reducer;

type ProductDetailProps = {
  targets: ItemCd[];
  products: ItemCd[];
};

const showProductDetailSlice = createSlice({
  name: 'showProductDetail',
  initialState: { targets: [], products: [] } as ProductDetailProps,
  reducers: {
    setShowProductDetailItemCd(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Type definition only used for Sakura state management
      state: ProductDetailProps,
      action: PayloadAction<ProductDetailProps>
    ) {
      return action.payload;
    },
    clearShowProductDetailItemCd(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Type definition only used for Sakura state management
      state: ProductDetailProps,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Payload not used but required for type definition
      action: PayloadAction<void>
    ) {
      return { targets: [], products: [] };
    },
  },
});

export const { setShowProductDetailItemCd, clearShowProductDetailItemCd } =
  showProductDetailSlice.actions;

export const SakuraShowProductDetailReducer = showProductDetailSlice.reducer;

export const selectContext = createSelector(
  (state: RootState) => state,
  (state) => state.SakuraEditorState.context
);

export const selectProducts = createSelector(
  (state: RootState) => state,
  (state) => state.SakuraEditorState.products
);

export const selectModegonProducts = createSelector(
  (state: RootState) => state,
  (state) => state.SakuraEditorState.modegonProducts
);

export const selectPreviousModegon = createSelector(
  (state: RootState) => state,
  (state) => state.SakuraEditorState.previousModegonItems
);

export const selectEliminationCandidate = createSelector(
  (state: RootState) => state,
  (state) => state.SakuraEditorState.eliminationCandidate
);

export const selectAddProductToModegon = createSelector(
  (state: RootState) => state,
  (state) => state.SakuraAddProductToModegon
);

export const selectShowProductDetail = createSelector(
  (state: RootState) => state,
  (state) => state.SakuraShowProductDetail
);
