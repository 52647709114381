import { SakuraAppBar } from '@components/pages/sakura/fragments/sakuraAppBar';
import { usePageTitle } from '@hooks/usePageTitle';
import { Error } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { fullHeight } from '@utils/const';
import { useNavigate } from 'react-router-dom';

const SakuraUnauthorized = () => {
  const navigate = useNavigate();
  usePageTitle('進化版モデルゴンドラ');
  return (
    <Box component="div" height={fullHeight}>
      <SakuraAppBar title="進化版モデルゴンドラ" />
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100%"
        sx={{ color: '#9E9E9E' }}
      >
        <Error
          sx={{
            width: '80px',
            height: '80px',
            mb: '14px',
            color: '#B5B5B5',
          }}
        />
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 'bold',
            letterSpacing: '0.15px',
            mb: 1,
            color: '#999999',
          }}
        >
          指定された店舗の閲覧はできません。
        </Typography>
        <Box component="div" sx={{ height: '50px' }} />
        <Button
          variant="outlined"
          onClick={() => {
            navigate('/sakura/view');
          }}
          sx={{
            p: 0,
            borderColor: CommonColors.appBlue,
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              lineHeight: '16px',
              fontWeight: 'bold',
              m: '12px 16px',
            }}
          >
            店舗選択画面に戻る
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default SakuraUnauthorized;
