import { paths } from 'types/sakuraApi';
import { baseApi, KeysToCamelcase } from '../baseApi';

export type ListModelGondolasResponse =
  paths['/api/v1/sakura/model_gondolas']['get']['responses']['200']['content']['application/json'];

type GetModelGondolaParams = KeysToCamelcase<
  paths['/api/v1/sakura/model_gondola/week={week}/zo_cd={zo_cd}/do_cd={do_cd}']['get']['parameters']
>;
export type GetModelGondolaResponse =
  paths['/api/v1/sakura/model_gondola/week={week}/zo_cd={zo_cd}/do_cd={do_cd}']['get']['responses']['200']['content']['application/json'];

type GetProductSaleParams = KeysToCamelcase<
  paths['/api/v1/sakura/product_sale/{store_cd}']['get']['parameters']
>;
export type GetProductSaleResponse =
  paths['/api/v1/sakura/product_sale/{store_cd}']['get']['responses']['200']['content']['application/json'];

export type GetSakuraStoresResponse =
  paths['/api/v1/sakura/stores']['get']['responses']['200']['content']['application/json'];

export type GetAvailableProductMasterResponse =
  paths['/api/v1/sakura/product_master/available_product_masters']['get']['responses']['200']['content']['application/json'];

export type GetProductMasterParams = KeysToCamelcase<
  paths['/api/v1/sakura/product_master/{date}/products_do={do_code}_gondola={gondola}.json']['get']['parameters']
>;

export type GetProductMasterResponse =
  paths['/api/v1/sakura/product_master/{date}/products_do={do_code}_gondola={gondola}.json']['get']['responses']['200']['content']['application/json'];

export type AvailableItems =
  paths['/api/v1/sakura/available_items']['get']['responses']['200']['content']['application/json'];

export type ListExternalInfoResponse =
  paths['/api/v1/sakura/external_info/list']['get']['responses']['200']['content']['application/json'];
export type GetProductAnnotationsQuery = KeysToCamelcase<
  paths['/api/v1/sakura/external_info/name={name}']['get']['parameters']
>;
export type GetExternalInfoResponse =
  paths['/api/v1/sakura/external_info/name={name}']['get']['responses']['200']['content']['application/json'];
export type ProductAnnotation =
  paths['/api/v1/sakura/external_info/name={name}']['get']['responses']['200']['content']['application/json']['annotations'][0];
export type NewItem =
  paths['/api/v1/sakura/external_info/name={name}']['get']['responses']['200']['content']['application/json']['newItems'][0];

export type SakuraUsers =
  paths['/api/v1/sakura/users']['get']['responses']['200']['content']['application/json'];
export type SakuraUser =
  paths['/api/v1/sakura/users']['get']['responses']['200']['content']['application/json']['users'][0];

// see) https://staging.shelf.pfn.dev/docs#/tenants

export const sakuraApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSakuraStores: builder.query<GetSakuraStoresResponse, void>({
      query: () => ({
        url: 'sakura/stores',
        method: 'GET',
      }),
    }),
    listModelGondolaItems: builder.query<ListModelGondolasResponse, void>({
      query: () => ({
        url: 'sakura/model_gondolas',
        method: 'GET',
      }),
    }),
    getModelGondola: builder.query<
      GetModelGondolaResponse,
      GetModelGondolaParams
    >({
      query: (params) => ({
        url: `sakura/model_gondola/week=${params.path.week}/zo_cd=${params.path.zoCd}/do_cd=${params.path.doCd}`,
        method: 'GET',
      }),
    }),
    getProductSale: builder.query<GetProductSaleResponse, GetProductSaleParams>(
      {
        query: (params) => ({
          url: `sakura/product_sale/${params.path.storeCd}`,
          method: 'GET',
        }),
      }
    ),

    getAvailableProductMaster: builder.query<
      GetAvailableProductMasterResponse,
      void
    >({
      query: () => ({
        url: `sakura/product_master/available_product_masters`,
        method: 'GET',
      }),
    }),

    getProductMaster: builder.query<
      GetProductMasterResponse,
      GetProductMasterParams
    >({
      query: (params) => ({
        url: `sakura/product_master/${params.path.date}/products_do=${params.path.doCode}_gondola=${params.path.gondola}.json`,
        method: 'GET',
      }),
    }),

    getAvailableItems: builder.query<AvailableItems, void>({
      query: () => ({
        url: 'sakura/available_items',
        method: 'GET',
      }),
    }),

    listExternalInfo: builder.query<ListExternalInfoResponse, void>({
      query: () => ({
        url: `sakura/external_info/list`,
        method: 'GET',
      }),
    }),
    getExternalInfo: builder.query<
      GetExternalInfoResponse,
      GetProductAnnotationsQuery
    >({
      query: (params) => ({
        url: `sakura/external_info/name=${params.path.name}`,
        method: 'GET',
      }),
    }),
    getSakuraUsers: builder.query<SakuraUsers, void>({
      query: () => ({
        url: 'sakura/users',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetModelGondolaQuery,
  useListModelGondolaItemsQuery,
  useGetProductSaleQuery,
  useGetSakuraStoresQuery,
  useGetAvailableProductMasterQuery,
  useGetProductMasterQuery,
  useGetAvailableItemsQuery,
  useListExternalInfoQuery,
  useGetExternalInfoQuery,
  useGetSakuraUsersQuery,
} = sakuraApi;
